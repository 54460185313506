<template>
  <table-overview
    title="Roles"
    store="roles"
    :headers="[
      { text: 'Name', value: 'name' },
      { text: 'Permissions', value: 'permissions', sortable: false },
      {
        text: 'Actions',
        align: 'center',
        sortable: false,
        class: 'v-table-th--icon',
        rolesOrPermissions: 'roles_view'
      }
    ]"
    :actions="actions"
    force-initial-sort
  >
    <role-table-row slot="table-row" slot-scope="{ item }" :role-id="item" />
  </table-overview>
</template>
<script type="text/babel">
import TableOverview from "../../components/generic/overview/TableOverview";
import rolesModule from "@/store/modules/roles";
import RoleTableRow from "../../components/role/RoleTableRow";
import { mapOverviewLifecycleMethods } from "../helpers";

const overviewLifecycleMethods = mapOverviewLifecycleMethods(
  "roles",
  rolesModule
);

export default {
  name: "roles-view",
  components: {
    RoleTableRow,
    TableOverview
  },
  data: () => ({
    actions: [
      {
        icon: "far fa-fw fa-plus",
        color: "pink",
        to: { name: "roles.create" },
        rolesOrPermissions: "roles_create"
      }
    ]
  }),
  ...overviewLifecycleMethods
};
</script>
