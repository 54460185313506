<template>
  <v-card color="white">
    <crud-header :title="name" type="Role" store="role" />
    <role />
  </v-card>
</template>

<script type="text/babel">
import roleModule from "@/store/modules/role";
import CrudHeader from "../../components/generic/crud/CrudHeader";
import { createNamespacedHelpers } from "vuex";
import { mapDetailViewLifecycleMethods } from "../helpers";
import Role from "../../components/role/Role";

const { mapGetters } = createNamespacedHelpers("role");

const detailViewLifecycleMethods = mapDetailViewLifecycleMethods(
  "role",
  roleModule
);

export default {
  name: "role-view",
  $_veeValidate: {
    validator: "new" // give me my own validator scope.
  },
  components: {
    Role,
    CrudHeader
  },
  data: () => {
    return {};
  },
  computed: {
    ...mapGetters(["name", "id"])
  },
  ...detailViewLifecycleMethods
};
</script>
