<template>
  <tr>
    <td>
      {{ role.name }}
    </td>
    <td>
      <v-chip
        v-for="(permission, index) in maxPermissions"
        :key="index"
        label
        small
      >
        {{ permission.name }}
      </v-chip>
      <v-tooltip v-if="permissions.length > 3" bottom>
        <v-chip slot="activator" v-html="'...'" />
        <span>
          <v-chip v-for="(permission, index) in permissions" :key="index" label>
            {{ permission.name }}
          </v-chip>
        </span>
      </v-tooltip>
    </td>
    <td>
      <v-btn icon :to="{ name: 'roles.show', params: { roleId: role.id } }">
        <v-icon>far fa-search</v-icon>
      </v-btn>
    </td>
  </tr>
</template>
<script type="text/babel">
import { createNamespacedHelpers } from "vuex";
import { FIND_BY_ID } from "@/store/templates/table/getter-types";

const { mapGetters: mapAuthGetters } = createNamespacedHelpers("auth");
const {
  mapGetters: mapRoleGetters,
  mapState: mapRoleState
} = createNamespacedHelpers("roles");

export default {
  name: "role-table-row",
  props: {
    roleId: {
      type: Number,
      required: true
    }
  },
  computed: {
    ...mapAuthGetters(["hasRolesOrPermissions"]),
    ...mapRoleGetters([FIND_BY_ID]),
    ...mapRoleState(["permission"]),
    role() {
      return this[FIND_BY_ID](this.roleId);
    },
    permissions: function() {
      return this.role.permissions.map(
        permissionId => this.permission[permissionId]
      );
    },
    maxPermissions: function() {
      return this.role.permissions
        .map(permissionId => this.permission[permissionId])
        .slice(0, 5);
    }
  }
};
</script>
