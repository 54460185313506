<template>
  <v-container fluid>
    <v-text-field
      v-model="name"
      v-validate="'required|max:255'"
      label="Name"
      autocomplete="not-my-name"
      :counter="255"
      :error-messages="errors.collect('name')"
      data-vv-name="name"
      required
    />
    <v-autocomplete-permission v-model="permissions" multiple />
  </v-container>
</template>

<script type="text/babel">
import { createNamespacedHelpers } from "@/store/helpers";
import VAutocompletePermission from "@/components/permission/VAutocompletePermission";
const { mapComputed } = createNamespacedHelpers("role");

export default {
  name: "role",
  components: { VAutocompletePermission },
  inject: ["$validator"],
  data: () => ({}),
  computed: {
    ...mapComputed(["name", "permissions"])
  }
};
</script>
