var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('table-overview',{attrs:{"title":"Roles","store":"roles","headers":[
    { text: 'Name', value: 'name' },
    { text: 'Permissions', value: 'permissions', sortable: false },
    {
      text: 'Actions',
      align: 'center',
      sortable: false,
      class: 'v-table-th--icon',
      rolesOrPermissions: 'roles_view'
    }
  ],"actions":_vm.actions,"force-initial-sort":""},scopedSlots:_vm._u([{key:"table-row",fn:function(ref){
  var item = ref.item;
return _c('role-table-row',{attrs:{"role-id":item}})}}])})}
var staticRenderFns = []

export { render, staticRenderFns }