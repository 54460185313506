<template>
  <v-card color="white">
    <crud-header
      :title="name"
      type="Role"
      store="role"
      @save-success="
        ({ id }) => $router.push({ name: 'roles.show', params: { roleId: id } })
      "
    />
    <role />
  </v-card>
</template>

<script type="text/babel">
import roleModule from "@/store/modules/role";
import CrudHeader from "../../components/generic/crud/CrudHeader";
import { createNamespacedHelpers } from "vuex";
import { mapCreateViewLifecycleMethods } from "../helpers";
import Role from "../../components/role/Role";

const { mapGetters } = createNamespacedHelpers("role");

const createViewLifecycleMethods = mapCreateViewLifecycleMethods(
  "role",
  roleModule
);

export default {
  name: "role-create-view",
  $_veeValidate: {
    validator: "new" // give me my own validator scope.
  },
  components: {
    Role,
    CrudHeader
  },
  computed: {
    ...mapGetters(["name", "permissions"])
  },
  ...createViewLifecycleMethods
};
</script>
