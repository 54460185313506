import crudMutations from "@/store/templates/crud/mutations";

export default {
  setName(state, name) {
    state.model.name = name;
  },
  setPermissions(state, permissions) {
    state.model.permissions = permissions;
  },
  ...crudMutations
};
